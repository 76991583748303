import React from "react";
import Footer_section from "./Footer_section";

const HeroSection = () => {
  return (
    <section
      className={`bg-gray-100 overflow-hidden md:snap relative flex flex-col h-full items-center justify-center gap-4`}
    >
      <div className="relative inset-0 w-full min-h-screen flex flex-col items-center justify-center text-white p-4">
        <video
          autoPlay
          muted
          loop
          playsInline
          className="absolute inset-0 size-full object-cover z-0 border-none overflow-hidden bg-black"
        >
          <source src="/hero_bg.mp4" type="video/mp4" />
        </video>

        <div className="size-full absolute bg-black opacity-60 inset-0"></div>
        <div className="bg-black/50 backdrop-blur-sm absolute h-[10vh] inset-0">
          <div>
            <img
              fetchPriority="high"
              className="sm:w-80 mt-10 ml-10"
              alt=""
              src="/Altrova_Logo.svg"
            />
          </div>
        </div>

        <h1 className="absolute text-zen-dots sm:text-[60px] text-[20px] flex justify-center sm:h-[500px] h-[950px] w-full">
          Shaping the Future of Artificial Intelligence
        </h1>

        <div className="w-fit min-[1352px]:w-full max-w-[1400px] h-full min-h-[350px] flex flex-col min-[1352px]:flex-row items-center justify-center p-6 gap-8 bg-black/50 backdrop-blur-sm rounded-xl mt-24 min-[1316px]:mt-80">
          <img
            fetchPriority="high"
            alt=""
            className="w-[350px] h-[200px]"
            src="/hero_section.svg"
          ></img>
          <div className="hidden relative min-[1352px]:flex w-[3px] h-[200px] bg-synapsify-red"></div>
          <div className="flex relative min-[1352px]:hidden w-full max-w-[800px] h-[3px] bg-synapsify-red"></div>
          <p className="w-full h-full max-w-[800px] text-xl md:text-justify">
            Altrova is an AI-first deep-tech startup committed to pioneering
            advancements in artificial intelligence and cutting-edge
            technologies. With a focus on building transformative solutions, our
            product lineup spans synthetic data generation, 3D content creation,
            emotive text-to-speech, AI-driven 3D facial animation, and
            serverless AI/ML training and deployment. Additionally, we lead
            innovation in vector databases and cloud-native log monitoring,
            setting the stage for a future where AI empowers seamless
            integration across digital ecosystems. Our long-term vision is to
            redefine the boundaries of AI, driving intelligent, scalable
            solutions that shape the future of technology
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
